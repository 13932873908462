<template>
  <Layout ref="main">
    <!-- <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col mb-3">
        <h1 class="font-size-24 mb-1 animate__animated animate__fadeInLeft">{{$t('siteLang.LoginTitle')}}</h1>
        <p class="text-muted mb-3 animate__animated animate__fadeInBottom">{{$t('siteLang.BorrowingMoney')}}</p>
      </div>
      <div class="col-auto text-end">
          <div class="input-group d-inline-block mb-0">
            <b-dropdown variant="light" right class="bg-white border shadow-none">
              <template v-slot:button-content>
                <img class="me-1" :src="selectedLang.flag" :alt="selectedLang.title" height="16" />
                {{ selectedLang.title }}
              </template>
              <b-dropdown-item
                class="notify-item"
                v-for="(value, i) in languages"
                :key="`Lang${i}`"
                :value="value"
                @click="setLanguage(value)"
              >
                <img
                  :src="`${value.flag}`"
                  alt="user-image"
                  class="me-1"
                  height="12"
                />
                <span class="align-middle"> {{ value.title }}</span>
              </b-dropdown-item>
            </b-dropdown>
            
          </div>
      </div>
      <div class="col-12">
         <div class="form-group mb-3">
          <label class="form-check-label mb-2 font-size-14 fw-normal text-muted"><i class="mdi mdi-cellphone-iphone me-1 font-size-16"></i> {{$t('siteLang.PhoneNumber')}}</label>
          <input type="tel" class="form-control form-control-lg bg-none" placeholder="62xxxxxxxxxx"  oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\+\d{1,2})\s?(\d{3})\s?(\d{4})/, '$1$2$3')"
          v-model.trim="formData.phone"
          :class="{
            'is-invalid': submitted && $v.formData.phone.$error,
          }">
          <div
            v-if="submitted && $v.formData.phone.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.formData.phone.required"
              >{{$t('siteLang.PhoneNumber')}} {{$t('siteLang.isRequired')}}</span
            >
            <span v-if="!$v.formData.phone.countryCodeAndPhone">
              {{$t('siteLang.PhoneMustError')}}
            </span>
          </div>
        </div>
        <div class="form-group mb-3">
          <label class="form-check-label mb-2 font-size-14 fw-normal text-muted"><i class="mdi mdi-lock-outline me-1 font-size-16"></i> {{ $t('siteLang.Password') }}</label>
          <div class="input-group">
              <input :type="passwordFieldType" class="form-control form-control-lg bg-none"  :placeholder="$t('siteLang.Password')" 
              v-model.trim="formData.password"
              :class="{
                'is-invalid': submitted && $v.formData.password.$error,
              }"
              >
              <span class="input-group-text bg-none"  @click="togglePasswordFieldType">
                <i class="mdi" :class="{'mdi-eye-off': !showPassword , ' mdi-eye':showPassword }"></i>
              </span>
              <div
              v-if="submitted && $v.formData.password.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.formData.password.required"
                >{{ $t('siteLang.Password') }} {{$t('siteLang.isRequired')}}</span
              >
            </div>
          </div>
         

          <a @click="forgotPassword" class="d-block mt-2 text-body text-end">
            {{$t('siteLang.ForgotPassword')}}
          </a>
        </div>
        <div class="form-group text-center text-muted my-3 font-size-13">
         {{ $t('siteLang.Yourpersonalinforsafe') }}
        </div>
        <div class="d-grid gap-2">
          <button class="btn btn-primary btn-lg fw-medium" :disabled="loading" @click="login" type="button">
           <span v-if="loading">{{$t('siteLang.Loging')}}.</span>
           <span v-else> {{$t('siteLang.Login')}} </span>
           <span class="spinner-border spinner-border-sm ms-2" v-if="loading" role="status"
            aria-hidden="true"></span>
          </button>
          <button class="btn btn-secondary btn-lg fw-medium" @click="register" type="button">{{$t('siteLang.Register')}}</button>
        </div>
        <div class="my-3 mb-5 px-0 form-check text-center text-muted">
          <input type="checkbox" class="form-check-input float-none me-2"
          :class="{
            'is-invalid': submitted && $v.formData.agreeTerm.$error,
          }"
          v-model="formData.agreeTerm" id="agree">
          <label class="form-check-label  d-inline fw-normal font-size-13 text-muted" for="agree">  {{ $t('siteLang.IagreedPrivacyPolicy') }}</label>
         <div
            v-if="submitted && $v.formData.agreeTerm.$error"
            class="invalid-feedback font-size-13"
          >
            <span v-if="!$v.formData.agreeTerm.required"
              >{{$t('siteLang.youMustAgree') }}</span
            >
          </div>

        </div>
      </div>
    </div>
    <Lottie :title="'Loading'" :info="''" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
  </Layout>
</template>
<script>
import Layout from "@/views/layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import { required, helpers} from "vuelidate/lib/validators";
export const countryCodeAndPhone = (value) => {
  const regex = /^(60|65|62|84)\d{8,15}$/
  return helpers.regex('countryCodeAndPhone', regex)(value)
}
import axios from "axios";
import Swal from "sweetalert2";
/**
 * Starter page
 */
//PageHeader
export default {
  components: { 
    Layout, 
    Lottie,
    Common
  },
  page() {
    return {
      title: this.$t('siteLang.Login'),
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  watch:{
    $route (to, from){
      console.log(to,from)
    }
  } ,
  data() {
    return {
      selectedLang: {},
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
        // {
        //   flag: require("@/assets/images/flags/china.png"),
        //   language: "zh",
        //   title: "Chinese",
        // },
        {
          flag: require("@/assets/images/flags/indonesia.png"),
          language: "id",
          title: "Indonesia",
        },
      ],
      loading:false,
      showLottie:false,
      showPassword: false,
      formData:{
        phone:"",
        password:"",
        agreeTerm:false,
      },
      submitted: false,
      title: '',
      items: [
        {
          text: appConfig.title,
          href: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
    };
  },
  validations: {
    formData:{
      phone: {
        required,
        countryCodeAndPhone,
      },
      password: {
        required,
      },
      agreeTerm: {
        checked: value => value === true,
      }
    
    }
   
  },
  computed: {
    passwordFieldType() {
      return this.showPassword ? 'text' : 'password'
    }
  },
  mounted(){
    this.$refs.main.changeVerticalTopBar("",false)
    if (this.$refs.commonFunc.getSetLang() ==undefined){
      this.$refs.commonFunc.setLang('id')
    }
    const Language = this.languages.find(lang => lang.language === this.$i18n.locale);
    this.selectedLang = Language

    this.title = this.$t('siteLang.Login')
    this.items[1].text = this.$t('siteLang.Login') 
  },
  created(){
    this.$Progress.finish();
  },
  methods:{
    togglePasswordFieldType() {
      this.showPassword = !this.showPassword
    },
    login(){
      this.submitted = true
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
          this.$Progress.start();
          this.loading = true
          var bodyFormData = new FormData();
          bodyFormData.append("phone",this.formData.phone);
          bodyFormData.append("password",this.formData.password);
          axios({
              method: "post",
              url: "https://www.seveninblank.com/projects/api/anythings.php",
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                const withdraw_amount = this.$refs.commonFunc.convertCurrencyFormat(parseFloat(10000))
                const data = {
                  "access_token":resData.access_token,
                  "name":"Testing" || '',
                  "phone":"60127781488",
                  "withdrawal_amount": withdraw_amount,//resData.withdrawal_amount,
                  "bank_account_name":"Testing Bank",
                  "bank_account":"123322233123",
                  "lang":"en",
                  "loanStatus":"90"
                }
                this.$refs.commonFunc.setData(data)
                // const path = this.$refs.commonFunc.getPreviosLink()
                // console.log(path)
                // this.$router.push(path)
                this.$router.push({ name: 'home'})
                // const token = this.$refs.commonFunc.getToken()
                // console.log(token)
              } else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  this.$Progress.fail();
              }
              this.loading = false;
              this.$Progress.finish();
          })
          .catch((error)=> {
              this.loading = false;
              this.$Progress.finish();
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      }
    },
   
    register(){
      this.$router.push({ name: 'register'})
    },
    setLanguage(value) {
      this.selectedLang = value
      this.$refs.commonFunc.setLang(value.language)
      this.$refs.commonFunc.getSetLang()
    },
    forgotPassword(){
      // this.showLottie = true
      // this.$refs.lottieFunc.showHide = true
      Swal.fire(
        {
        icon: 'info',
        title: this.$t('siteLang.ForgotPassword'),
        iconColor: '#04da8c',
        html:  this.$t('siteLang.ForgotPasswordDesc'),
        confirmButtonColor: '#222',
        confirmButtonText: this.$t('siteLang.Okay'),
      })
    }
  },
  middleware: "authentication",
};
</script>
<style scoped>
.bg-none{
  background: none;
}
.form-check-input[type=checkbox] {
  width: 1.2em;
  height: 1.2em;
  margin-top: 3px;
  margin-left: 0;
  margin-right: 0;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #ced4da;
  display: inline-block;
  background-position: 0px;
}

.form-check-input[type=checkbox]:checked {
  background-color:#04DA8C!important ;
  border-color: #04DA8C!important ;
}
</style>