var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',{ref:"main"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col mb-3"},[_c('h1',{staticClass:"font-size-24 mb-1 animate__animated animate__fadeInLeft"},[_vm._v(_vm._s(_vm.$t('siteLang.LoginTitle')))]),_c('p',{staticClass:"text-muted mb-3 animate__animated animate__fadeInBottom"},[_vm._v(_vm._s(_vm.$t('siteLang.BorrowingMoney')))])]),_c('div',{staticClass:"col-auto text-end"},[_c('div',{staticClass:"input-group d-inline-block mb-0"},[_c('b-dropdown',{staticClass:"bg-white border shadow-none",attrs:{"variant":"light","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('img',{staticClass:"me-1",attrs:{"src":_vm.selectedLang.flag,"alt":_vm.selectedLang.title,"height":"16"}}),_vm._v(" "+_vm._s(_vm.selectedLang.title)+" ")]},proxy:true}])},_vm._l((_vm.languages),function(value,i){return _c('b-dropdown-item',{key:`Lang${i}`,staticClass:"notify-item",attrs:{"value":value},on:{"click":function($event){return _vm.setLanguage(value)}}},[_c('img',{staticClass:"me-1",attrs:{"src":`${value.flag}`,"alt":"user-image","height":"12"}}),_c('span',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(value.title))])])}),1)],1)]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-check-label mb-2 font-size-14 fw-normal text-muted"},[_c('i',{staticClass:"mdi mdi-cellphone-iphone me-1 font-size-16"}),_vm._v(" "+_vm._s(_vm.$t('siteLang.PhoneNumber')))]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.formData.phone),expression:"formData.phone",modifiers:{"trim":true}}],staticClass:"form-control form-control-lg bg-none",class:{
          'is-invalid': _vm.submitted && _vm.$v.formData.phone.$error,
        },attrs:{"type":"tel","placeholder":"62xxxxxxxxxx","oninput":"this.value = this.value.replace(/[^0-9]/g, '').replace(/(\\+\\d{1,2})\\s?(\\d{3})\\s?(\\d{4})/, '$1$2$3')"},domProps:{"value":(_vm.formData.phone)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "phone", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.submitted && _vm.$v.formData.phone.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.formData.phone.required)?_c('span',[_vm._v(_vm._s(_vm.$t('siteLang.PhoneNumber'))+" "+_vm._s(_vm.$t('siteLang.isRequired')))]):_vm._e(),(!_vm.$v.formData.phone.countryCodeAndPhone)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('siteLang.PhoneMustError'))+" ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-check-label mb-2 font-size-14 fw-normal text-muted"},[_c('i',{staticClass:"mdi mdi-lock-outline me-1 font-size-16"}),_vm._v(" "+_vm._s(_vm.$t('siteLang.Password')))]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.formData.password),expression:"formData.password",modifiers:{"trim":true}}],staticClass:"form-control form-control-lg bg-none",class:{
              'is-invalid': _vm.submitted && _vm.$v.formData.password.$error,
            },attrs:{"type":_vm.passwordFieldType,"placeholder":_vm.$t('siteLang.Password')},domProps:{"value":(_vm.formData.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "password", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"input-group-text bg-none",on:{"click":_vm.togglePasswordFieldType}},[_c('i',{staticClass:"mdi",class:{'mdi-eye-off': !_vm.showPassword , ' mdi-eye':_vm.showPassword }})]),(_vm.submitted && _vm.$v.formData.password.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.formData.password.required)?_c('span',[_vm._v(_vm._s(_vm.$t('siteLang.Password'))+" "+_vm._s(_vm.$t('siteLang.isRequired')))]):_vm._e()]):_vm._e()]),_c('a',{staticClass:"d-block mt-2 text-body text-end",on:{"click":_vm.forgotPassword}},[_vm._v(" "+_vm._s(_vm.$t('siteLang.ForgotPassword'))+" ")])]),_c('div',{staticClass:"form-group text-center text-muted my-3 font-size-13"},[_vm._v(" "+_vm._s(_vm.$t('siteLang.Yourpersonalinforsafe'))+" ")]),_c('div',{staticClass:"d-grid gap-2"},[_c('button',{staticClass:"btn btn-primary btn-lg fw-medium",attrs:{"disabled":_vm.loading,"type":"button"},on:{"click":_vm.login}},[(_vm.loading)?_c('span',[_vm._v(_vm._s(_vm.$t('siteLang.Loging'))+".")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('siteLang.Login'))+" ")]),(_vm.loading)?_c('span',{staticClass:"spinner-border spinner-border-sm ms-2",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e()]),_c('button',{staticClass:"btn btn-secondary btn-lg fw-medium",attrs:{"type":"button"},on:{"click":_vm.register}},[_vm._v(_vm._s(_vm.$t('siteLang.Register')))])]),_c('div',{staticClass:"my-3 mb-5 px-0 form-check text-center text-muted"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.agreeTerm),expression:"formData.agreeTerm"}],staticClass:"form-check-input float-none me-2",class:{
          'is-invalid': _vm.submitted && _vm.$v.formData.agreeTerm.$error,
        },attrs:{"type":"checkbox","id":"agree"},domProps:{"checked":Array.isArray(_vm.formData.agreeTerm)?_vm._i(_vm.formData.agreeTerm,null)>-1:(_vm.formData.agreeTerm)},on:{"change":function($event){var $$a=_vm.formData.agreeTerm,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formData, "agreeTerm", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formData, "agreeTerm", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formData, "agreeTerm", $$c)}}}}),_c('label',{staticClass:"form-check-label d-inline fw-normal font-size-13 text-muted",attrs:{"for":"agree"}},[_vm._v(" "+_vm._s(_vm.$t('siteLang.IagreedPrivacyPolicy')))]),(_vm.submitted && _vm.$v.formData.agreeTerm.$error)?_c('div',{staticClass:"invalid-feedback font-size-13"},[(!_vm.$v.formData.agreeTerm.required)?_c('span',[_vm._v(_vm._s(_vm.$t('siteLang.youMustAgree')))]):_vm._e()]):_vm._e()])])]),_c('Lottie',{ref:"lottieFunc",attrs:{"title":'Loading',"info":'',"show":_vm.showLottie}}),_c('Common',{ref:"commonFunc"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }